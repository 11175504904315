import React from 'react';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import useCallApi from "../hooks/useCallApi";

const ModalFormStudent = (props) => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const callApi = useCallApi();
  const { updateStudent } = props;

  const onSubmit = () => {
    Swal.clickConfirm()
  };

  const closeModal = () => {
    Swal.clickCancel()
  };
  async function handleClickCreateCertificate(isMention, studentId, moduleId) {

    //create the certificate object
    const certificate = {
      ModuleId: moduleId,
      IsMention: isMention,
      StudentId: studentId,
    };

    //put the certificate into an array, the backend expect a list of certificates
    const data = [certificate];

    try {
      await callApi.callApiNoRead("certificate", data, true, false);
      //open again update modal
      setTimeout(() => {
        updateStudent(studentId);
      }, 1000);

    } catch (error) {
      console.error('Error:', error);

    }
  }

  async function handleClickDeleteCertificate(id, code, isMention, studentId) {

    //create the certificate object
    const certificate = {
      CertificateId: id,
      IsMention: isMention,
      Code: code,
    };

    try {
      await callApi.callApiNoRead("Certificate/CertificateDelete", certificate, true, false);
      //open again update modal
      setTimeout(() => {
        updateStudent(studentId);
      }, 1000);

    } catch (error) {
      console.error('Error:', error);

    }
  }
  handleClickDeleteMention
  async function handleClickChangeFinished(finished, idStudent, idModule) {

    const params = new URLSearchParams({ idStudent, idModule, finished });
    try {
      await callApi.callApiNoRead(`Module/ChangeFinishedModuleByIdStudent?${params.toString()}`, null, true, false);
      //open again update modal
      setTimeout(() => {
        updateStudent(idStudent);
      }, 1000);

    } catch (error) {
      console.error('Error:', error);
    }
  }

  async function handleClickDeleteMention(idMentionCertificate, code, idStudent) {

    const params = new URLSearchParams({idMentionCertificate, code });
    try {
      await callApi.callApiNoRead(`Certificate/CertificateMentionDelete?${params.toString()}`, null, true, false);
      //open again update modal
      setTimeout(() => {
        updateStudent(idStudent);
      }, 1000);

    } catch (error) {
      console.error('Error:', error);
    }
  }

  //Conditional input, if the form is a update form, the defaultValue will be filled in the try. Else, the input will not have a defaultValue
  function Input({ allProps, theKey }) {
    let input;
    try {
      input = <input
        id={theKey}
        type={allProps.inputs[theKey][2]}
        defaultValue={allProps.data[theKey]}
        className={errors[theKey] ? 'mb-4 form-control is-invalid ' : 'mb-4 form-control'}
        {...register(theKey, { required: allProps.inputs[theKey][1] })} />
    } catch {
      input = <input
        id={theKey}
        type={allProps.inputs[theKey][2]}
        className={errors[theKey] ? 'mb-4 form-control is-invalid ' : 'mb-4 form-control'}
        {...register(theKey, { required: allProps.inputs[theKey][1] })} />
    }
    return input
  }

  //create the form with the inputs of the prop "inputs"
  return (
    <form id={props.idForm} className="container-fluid mt-5" onSubmit={handleSubmit(onSubmit)}>
      <div className='row justify-content-center mb-3'>
        {Object.keys(props.inputs).map((key, index) =>
          <div key={index} className='col-3'>
            <label className='form-label'>{props.inputs[key][0]}</label>
            <Input allProps={props} theKey={key} />
          </div>
        )}

        <textarea placeholder='Observaciones' defaultValue={props.data ? props.data['remarks'] : ''} className="form-control" {...register('remarks')} rows="3"></textarea>
        <button className='swal2-confirm swal2-styled mt-5 w-25' type="submit" >Guardar</button>

        {/*update Form*/}


        {props.idForm === "updateForm" && (
          <div>
            <hr />
            <h4 className='mt-5 pt-5'>Certificados</h4>

            <table className="table my-5 table-hover pb-5">
              <thead>
                <tr>
                  <th scope='col'>Código</th>
                  <th scope="col">Fecha Certificado</th>
                  <th scope='col'>Mención</th>
                  <th scope='col'>modulo</th>
                  <th scope='col'>Curso</th>
                  <th scope='col'>Mención</th>
                  <th scope='col'>Eliminar</th>

                </tr>
              </thead>
              <tbody>

                {props.data.Certificates && props.data.Certificates.map((certificate, index) => (
                  <tr key={index}>
                    <td className="col-md-2">{certificate.code || 'N/A'}</td>
                    <td className="col-md-1">{certificate.dateCertificate || 'N/A'}</td>
                    <td className="col-md-1">{certificate.isMention ? 'Sí' : 'No'}</td>
                    <td className="col-md-2">{certificate.module?.name || 'N/A'}</td>
                    <td className="col-md-3">{certificate.course?.name || 'N/A'}</td>
                    <td className="col-md-2">{certificate.isMention ? <button onClick={() => handleClickDeleteMention(props.data.Certificates[index].idCertificate, props.data.Certificates[index].code, props.data.id)} className='swal2-confirm swal2-styled fs-6' type='button'>Eliminar Mención</button> : <button onClick={() => handleClickCreateCertificate(true, props.data.id, props.data.Certificates[index].module.id)} className='swal2-confirm swal2-styled fs-6' type='button'>Agregar Mención</button>}</td>
                    <td className="col-md-1">
                      <button className='swal2-confirm swal2-styled fs-6' type='button' onClick={() => handleClickDeleteCertificate(props.data.Certificates[index].idCertificate, props.data.Certificates[index].code, props.data.Certificates[index].isMention, props.data.id)}>Eliminar</button>
                    </td>

                  </tr>
                ))}
              </tbody>
            </table>


            <div>
              <h4 className='mt-5 pt-5'>Módulos No Finalizados</h4>
              <table className="table my-5 pb-5 mb-5 table-hover">
                <thead>
                  <tr>
                    <th scope="col">Modulo</th>
                    <th scope='col'>Finalizado</th>
                    <th scope='col'>Crear Certificado</th>
                    <th scope='col'>Crear certificado y Mencion de honor</th>
                  </tr>
                </thead>
                <tbody>
                  {props.data.ModulesNotFinished && props.data.ModulesNotFinished.map((moduleInfo, index) => (
                    <tr key={index}>
                      <td className="col-md-3">{moduleInfo.module.Name}</td>
                      <td className="col-md-2"><button onClick={() => handleClickChangeFinished(true, props.data.id, props.data.ModulesNotFinished[index].module.id)} className='swal2-confirm swal2-styled' type='button' >Solo dar por finalizado</button></td>
                      <td className="col-md-2">
                        <button className='swal2-confirm swal2-styled' type='button' onClick={() => handleClickCreateCertificate(false, props.data.id, props.data.ModulesNotFinished[index].module.id)} >Crear Certificado </button>
                      </td>
                      <td className="col-md-3">
                        <button className='swal2-confirm swal2-styled' type='button' onClick={() => handleClickCreateCertificate(true, props.data.id, props.data.ModulesNotFinished[index].module.id)} >  Crear certificado y Mención de honor </button>
                      </td>

                    </tr>
                  ))}

                </tbody>
              </table>
            </div>

            <div>
              <h4 className='mt-5 pt-5'>Módulos Finalizados sin certificado</h4>
              <table className="table mb-5 pb-5 my-5 table-hover">
                <thead>
                  <tr>
                    <th scope="col">Modulo</th>
                    <th scope='col'>Finalizado</th>
                    <th scope='col'>Crear Certificado</th>
                    <th scope='col'>Crear certificado y Mencion de honor</th>
                  </tr>
                </thead>
                <tbody>
                  {props.data.ModulesOnlyFinished && props.data.ModulesOnlyFinished.map((moduleInfo, index) => (
                    <tr key={index}>
                      <td className="col-md-3">{moduleInfo.module.Name}</td>
                      <td className="col-md-2"><button onClick={() => handleClickChangeFinished(false, props.data.id, props.data.ModulesOnlyFinished[index].module.id)} className='swal2-confirm swal2-styled' type='button' >Eliminar finalizado</button></td>
                      <td className="col-md-2">
                        <button className='swal2-confirm swal2-styled' type='button' onClick={() => handleClickCreateCertificate(false, props.data.id, props.data.ModulesOnlyFinished[index].module.id)} >Crear Certificado </button>
                      </td>
                      <td className="col-md-3">
                        <button className='swal2-confirm swal2-styled' type='button' onClick={() => handleClickCreateCertificate(true, props.data.id, props.data.ModulesOnlyFinished[index].module.id)} >  Crear certificado y Mención de honor </button>
                      </td></tr>
                  ))}

                </tbody>
              </table>
            </div>

            <div className='pb-5 mb-4'>
              <h4 className='mt-5 pt-5 '>Grupos a los que pertenece</h4>
              <ul className='mt-5 list-group list-group-flush'>
                {props.data.Groups && props.data.Groups.map((group, index) => (
                  <li className='list-group-item' key={index}>{group.name}</li>
                ))}
              </ul>
            </div>

          </div>

        )}
        <hr />


      </div>
      <button className='swal2-confirm swal2-styled ' type="submit" >Guardar</button>
      <button onClick={closeModal} type="button" className="swal2-cancel swal2-styled" aria-label="" >Cancelar</button>
    </form>
  );
};

export default ModalFormStudent;
