import { React } from "react";
import { useEffect, useState } from "react";
import useCallApi from "../hooks/useCallApi";
import { useParams } from 'react-router-dom';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";


function GroupCertificates() {

    const params = useParams();

    const [items, setItems] = useState(null);

    const callApi = useCallApi();

    const [studentListForUpdate, setStudentListForUpdate] = useState();

    //aux variable for handleCheckboxChange
    let auxItems;

    useEffect(() => {
        const fetchData = async () => {
            const response = await callApi.getCall("Group/AllDataGroup/" + params.group)
            console.log(response)
            setItems(response)
            if (response != "") {
                setStudentListForUpdate(
                    response.module.map((module) =>
                        module.student.map((student) => ({
                            idStudent: student.idStudent,
                            idModule: module.id,
                            finished: student.finished == true,
                            hasCertificate: student.certificate[0]?.code !== null,
                            hasMention: student.certificate[0]?.isMention == true,
                            hasChanged: false
                        }))
                    ))
            }
        }

        fetchData();
        // eslint-disable-next-line
    }, [params.group]);


    const handleCheckboxChange = (event, moduleIndex, studentIndex, type) => {
        auxItems = { ...items };

        studentListForUpdate[moduleIndex][studentIndex].hasChanged = true;

        switch (type) {
            case 'finished':
                auxItems.module[moduleIndex].student[studentIndex].finished = event.target.checked;
                studentListForUpdate[moduleIndex][studentIndex].finished = event.target.checked;

                if (event.target.checked == false) {
                    //Certificate = false
                    auxItems.module[moduleIndex].student[studentIndex].certificate[0].code = false;
                    studentListForUpdate[moduleIndex][studentIndex].hasCertificate = false;
                    //finished = false
                    auxItems.module[moduleIndex].student[studentIndex].certificate[0].isMention = false;
                    studentListForUpdate[moduleIndex][studentIndex].hasMention = false;
                }
                break;

            case 'certificate':
                auxItems.module[moduleIndex].student[studentIndex].certificate[0].code = event.target.checked;
                studentListForUpdate[moduleIndex][studentIndex].hasCertificate = event.target.checked;
                if (event.target.checked == true) {
                    //finished = true
                    auxItems.module[moduleIndex].student[studentIndex].finished = true;
                    studentListForUpdate[moduleIndex][studentIndex].finished = true;
                } else {
                    auxItems.module[moduleIndex].student[studentIndex].certificate[0].isMention = false;
                    studentListForUpdate[moduleIndex][studentIndex].hasMention = false;
                }
                break;

            case 'mention':
                auxItems.module[moduleIndex].student[studentIndex].certificate[0].isMention = event.target.checked;
                studentListForUpdate[moduleIndex][studentIndex].hasMention = event.target.checked;
                if (event.target.checked == true) {

                    //Certificate = true
                    if (!auxItems.module[moduleIndex].student[studentIndex].certificate[0].code) {
                        auxItems.module[moduleIndex].student[studentIndex].certificate[0].code = true;
                        studentListForUpdate[moduleIndex][studentIndex].hasCertificate = true;
                    }

                    //finished = true
                    auxItems.module[moduleIndex].student[studentIndex].finished = true;
                    studentListForUpdate[moduleIndex][studentIndex].finished = true;
                }

                break;
            default:
                break;
        }
        setItems(auxItems);

    };

    const generateModuleCertificates = (index) => {

        const newItems = { ...items }; //copy of the items
        newItems.module[index].student.forEach((student, studentIndex) => {
            student.finished = true;
            student.certificate[0].code = true

            studentListForUpdate[index][studentIndex].hasChanged = true;
            studentListForUpdate[index][studentIndex].finished = true;
            studentListForUpdate[index][studentIndex].hasCertificate = true;
        });
        setItems(newItems);

    }

    const saveChanges = () => {

        let arr = studentListForUpdate;

        let result = arr.reduce((acc, current) => {
            let filtered = current.filter(item => item.hasChanged === true);
            return acc.concat(filtered);
        }, []);

        result.sort((a, b) => a.idModule - b.idModule);

        //groupedResult: arrays of objects grouped by "idModule"
        let groupedResult = result.reduce((groups, item) => {
            const group = groups.find(g => g.length > 0 && g[0].idModule === item.idModule);
            if (!group) {
                groups.push([item]);
            } else {
                group.push(item);
            }
            return groups;
        }, []);

        if (groupedResult.length > 0)
            callApi.callApiNoRead("Group/UpdateGroupCertificates", groupedResult)
        else {
            withReactContent(Swal).fire({
                icon: 'error',
                title: "No se realizo ningun cambios",
                confirmButtonText: "Aceptar"
            })
        }

    }

    return (
        <>

            {items === null ? (
                <div>Cargando...</div>
            ) : items.module && items.module.length > 0 ? (
                <>
                    <div className="row mt-5">
                        <div className="col-md-10">
                            <h1 className="fs-2">Gestion Certificados {items.name}</h1>
                        </div>
                        <div className="col-md-2">
                            <button onClick={saveChanges} className="btn btn-danger mt-2">Guardar Cambios</button>
                        </div>
                    </div>
                    <hr />
                    <div className="container mt-5">
                        <div >
                            {items.module.map((module, indexModule) => (
                                <div key={`${indexModule}-${module.moduleName}`}>

                                    <div className="row mt-5">
                                        <div className="col-md-10">
                                            <h4>{module.moduleName}</h4>
                                        </div>
                                        <div className="col-md-2">
                                            <button onClick={() => generateModuleCertificates(indexModule)} className="btn btn-warning">Generar Certificados</button>
                                        </div>
                                    </div>

                                    <table className="table my-5 table-hover">
                                        <thead>
                                            <tr>
                                                <th scope="col">Nombre</th>
                                                <th scope="col">Apellido</th>
                                                <th scope='col'>Terminado</th>
                                                <th scope='col'>Certificado</th>
                                                <th scope='col'>Mencion de Honor</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {module.student.map((item, indexStudent) => (
                                                <tr key={`${indexModule}-${item.idStudent}`} id={item.id}>
                                                    <td className="col-md-2">{item.name}</td>
                                                    <td className="col-md-2">{item.surname}</td>
                                                    <td className='col-md-2'>
                                                        <input type="checkbox" checked={item.finished == true} className="form-check-input" onChange={(event) => handleCheckboxChange(event, indexModule, indexStudent, 'finished')}></input>
                                                    </td>
                                                    <td className='col-md-3'>
                                                        <div className="d-flex">
                                                            <input type="checkbox" checked={item.certificate[0]?.code != null && item.certificate[0]?.code !== false} className="form-check-input mx-2" onChange={(event) => handleCheckboxChange(event, indexModule, indexStudent, 'certificate')}></input>
                                                            <a target="_blank" rel="noopener noreferrer" className="text-decoration-none cursor-pointer" href={"https://it-arg.com/certificado/" + item?.certificate[0]?.code}>
                                                                {item.certificate[0]?.code && item.certificate[0]?.code != true ? "Ver " + item.certificate[0]?.code : null}
                                                            </a>
                                                        </div>

                                                    </td>
                                                    <td className='col-md-3'>

                                                        <div className="d-flex">
                                                            <input type="checkbox" checked={item.certificate[0]?.isMention == true} className="form-check-input mx-2" onChange={(event) => handleCheckboxChange(event, indexModule, indexStudent, 'mention')}></input>
                                                            <a target="_blank" className="text-decoration-none cursor-pointer" rel="noopener noreferrer" href={"https://it-arg.com/certificado/" + item?.certificate[0]?.code + "m"}>
                                                                {item.certificate[0]?.isMention && item.certificate[0]?.code != true ? "Ver " + item.certificate[0]?.code + "m" : null}
                                                            </a>
                                                        </div>
                                                    </td>

                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            ))}

                        </div>
                    </div>
                </>
            ) : (
                <div className="mt-5">No hay alumnos en el grupo o modulos en el curso</div>
            )}

        </>
    )
}
export default GroupCertificates