import React, { useEffect, useState } from "react";
import useCallApi from "../hooks/useCallApi";
import { useParams } from 'react-router-dom';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ModalFormModule from "../components/ModalFormModule";

function Modules() {
    const params = useParams();
    const [items, setItems] = useState(null);
    const [descriptionValues, setDescriptionValues] = useState({});
    const [moduleNames, setModuleNames] = useState({}); // State to store updated module names
    const callApi = useCallApi();

    useEffect(() => {
        const fetchData = async () => {
            const response = await callApi.getCall("Module/ModuleGetByIdCourse/" + params.idCourse);
            setItems(response);

            const initialDescriptions = {};
            const initialModuleNames = {}; // Object to store initial module names
            if (response != "") {
                response.forEach(moduleItem => {
                    initialModuleNames[moduleItem.id] = moduleItem.name; // Store initial name
                    moduleItem.moduleItem.forEach(descriptionItem => {
                        initialDescriptions[descriptionItem.id] = descriptionItem.description;
                    });
                });
                setDescriptionValues(initialDescriptions);
                setModuleNames(initialModuleNames); // Set initial names
            }
        };
        fetchData();
    }, [params.idCourse]);

    const addModule = () => {
        withReactContent(Swal).fire({
            title: 'Agregar Modulo',
            html: <ModalFormModule idForm="addForm" />,
            width: '60%',
            showConfirmButton: false,
        }).then((result) => {
            if (result.isConfirmed) {
                callApi.callApiNoReadWithModal("module/" + params.idCourse, "#addForm");
            }
        });
    };

    const handleDeleteModule = (moduleId, name) => {
        callApi.deleteCall("module/Delete/" + moduleId, 'al modulo ' + name);
    };

    const handleInputChange = (id, value) => {
        setDescriptionValues(prevValues => ({
            ...prevValues,
            [id]: value
        }));
    };

    const handleEditDescriptionItemModule = (descriptionId) => {
        const updatedValue = descriptionValues[descriptionId];
        callApi.callApiNoRead("module/updateItem/" + descriptionId, updatedValue.toString(), true, false);
    };

    // Function to handle changes in module name inputs
    const handleModuleNameChange = (moduleId, newName) => {
        setModuleNames(prevNames => ({
            ...prevNames,
            [moduleId]: newName
        }));
    };

    // Function to save updated module name to backend
    const handleSaveModuleName = (moduleId) => {
        const updateModuleName = moduleNames[moduleId];
        // Call to backend to update the module name
        callApi.callApiNoRead("module/update/" + moduleId, updateModuleName.toString(), true, false);
    };

    return (
        <div className="container mt-4">
            <button onClick={addModule} className="btn btn-success mt-1 mb-4">Agregar nuevo Modulo</button>
            <hr></hr>
            {items && items.length > 0 ? (
                items.map((moduleItem) => (
                    <div key={moduleItem.id} className="mb-5">
                        <hr></hr>
                        <h4>Modulo</h4>
                        <div className="row row-cols-auto mb-4 mt-5">

                            <div className="col">
                                <button onClick={() => handleDeleteModule(moduleItem.id, moduleNames[moduleItem.id])} className="btn btn-danger">Eliminar modulo entero</button>
                            </div>

                            <div className="col-md-4">
                                <input
                                    type="text"
                                    className="form-control"
                                    maxLength={45}
                                    value={moduleNames[moduleItem.id] || ''}
                                    onChange={(e) => handleModuleNameChange(moduleItem.id, e.target.value)}
                                />
                            </div>
                            <div className="col">
                                <button onClick={() => handleSaveModuleName(moduleItem.id)} className="btn btn-warning">Guardar Modificación</button>
                            </div>
                        </div>
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th scope="col">Descripción</th>
                                    <th scope="col">Guardar cambios</th>
                                </tr>
                            </thead>
                            <tbody>
                                {moduleItem.moduleItem.map((descriptionItem) => (
                                    <tr key={descriptionItem.id}>
                                        <td className="col-md-4">
                                            <input
                                                type="text"
                                                className="form-control"
                                                maxLength={45}
                                                value={descriptionValues[descriptionItem.id] || ''}
                                                onChange={(e) => handleInputChange(descriptionItem.id, e.target.value)}
                                            />
                                        </td>
                                        <td className="col-md-3">
                                            <button
                                                onClick={() => handleEditDescriptionItemModule(descriptionItem.id)}
                                                className="btn btn-warning"
                                            >
                                                Guardar
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                ))
            ) : (
                <p>No hay módulos disponibles.</p>
            )}
        </div>
    );
}

export default Modules;
