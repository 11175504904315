import { React } from "react";
import { useEffect, useState } from "react";
import useCallApi from "../hooks/useCallApi";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ModalFormGroups from "../components/ModalFormGroups";
import { NavLink } from "react-router-dom";


function Groups() {

    const callApi = useCallApi();
    const [items, setItems] = useState([]);
    const [filtredData, setFiltredData] = useState();

    useEffect(() => {
        const fetchData = async () => {
            const response = await callApi.getCall("Group")
            if (response !== "") {
                setItems(response)
                setFiltredData(response)
            }
        }
        document.getElementById('searchInput').focus();
        fetchData();
        // eslint-disable-next-line
    }, []);


    //Data filter
    const handleFilter = (event) => {
        const searchTerm = event.target.value.toLowerCase();
        const filtered = items.filter(items =>
            //Filter properties
            items.name.toLowerCase().includes(searchTerm)
        );
        setFiltredData(filtered);
    };

    const deleteItem = (id, name) => {
        callApi.deleteCall("group/Delete/" + id, 'al grupo ' + name);
    }

    const add = async () => {

        withReactContent(Swal).fire({
            title: 'Agregar grupo',
            html: <ModalFormGroups idForm="addForm" />,
            width: '60%',
            grow: false,
            showClass: {
                popup: ''
            },
            showConfirmButton: false,
        })
    }

    const update = async (id, name) => {
        withReactContent(Swal).fire({
            title: 'Modificar grupo ' + name,
            html: <ModalFormGroups id={id} idForm="updateForm" />,
            width: '60%',
            grow: false,
            showClass: {
                popup: ''
            },
            showConfirmButton: false,
        })
    }

    return (
        <div>
            <main className="container">
                <button onClick={add} className="btn btn-success mt-4">Agregar nuevo grupo</button>
                <input type="text" id="searchInput" placeholder="Buscar grupo" className="form-control mt-4" onChange={handleFilter} />
                <table className="table mt-3 table-hover">
                    <thead>
                        <tr>
                            <th className="text-center col-md-8" scope="col">Nombre</th>
                            <th className="text-center" scope="col">Gestionar Certificados</th>
                            <th className="text-center" scope="col">Modificar</th>
                            <th className="text-center" scope="col">Eliminar</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filtredData ? (
                            filtredData.map((item) => (
                                <tr key={item.id}>
                                    <td className="text-center col-md-8">{item.name}</td>
                                    <td className="text-center"><NavLink className="btn btn-primary" to={"/Grupos/Certificados/" + item.id}>Certificados</NavLink></td>
                                    <td className="text-center"><button onClick={() => update(item.id, item.name)} className="btn btn-warning">Modificar</button></td>
                                    <td className="text-center"><button onClick={() => deleteItem(item.id, item.name)} className="btn btn-danger">Eliminar</button></td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td className="text-center mt-5">Cargando...</td>
                                <td className="text-center mt-5">Cargando...</td>
                                <td className="text-center mt-5"></td>
                                <td className="text-center mt-5"></td>

                            </tr>
                        )}
                    </tbody>
                </table>
            </main>
        </div>
    )
}
export default Groups