import React from "react";
import { Outlet, NavLink } from "react-router-dom";
import "./styles/App.css"
import useLogout from '../hooks/useLogout';
import { useNavigate } from 'react-router-dom';

function App() {

    const navigate = useNavigate();
    const logout = useLogout();

    const signOut = async () => {
        await logout();
        navigate('/login');
    }


    return (
        <>
            <header className="container">
                <nav className="fs-6 navbar sticky-top navbar-expand-lg navbar-dark bg-dark  mt-4">
                    <div className="container-fluid ">

                        <NavLink className="fs-6 navbar-brand" to="/">Inicio</NavLink >
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarText">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                               
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/Alumnos">Alumnos</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/Grupos">Grupos</NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/cursos">Cursos</NavLink>
                                </li>
                                {/* <li className="nav-item">
            <NavLink className="nav-link" to="/certificados">Certificados</NavLink>
        </li> */}
                            </ul>
                            <ul className="navbar-nav mb-2 mb-lg-0">
                                <li className="nav-item">
                                    <span onClick={signOut} className="nav-link fs-6 pt-1">Cerrar sesión</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>
            <main className='container'>
                <Outlet />
            </main>

        </>
    )
}
export default App
