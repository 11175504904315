import axios from '../API & Auth/Axios';
import useAuth from './useAuth';

const useRefreshToken = () => {
    const { setAuth } = useAuth();


    const refresh = async () => {

        try {
            const response = await axios.post('Authentication/Refresh');
            setAuth(prev => {
                return { ...prev, accessToken: response.data.accessToken }
            });            
            console.log("RefreshT")

            return response.data.accessToken;
        } catch (error) {
            console.error("Error refreshing token", error);
        }        
    };
    return refresh;

};

export default useRefreshToken;