import { React } from "react";
import { useEffect, useState } from "react";
import useCallApi from "../hooks/useCallApi";
import ModalFormCourse from "../components/ModalFormCourse";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { Link } from 'react-router-dom';


function Courses() {

    const callApi = useCallApi();
    const [courses, setCourses] = useState([]);
    const [filtredData, setFiltredData] = useState();

    //send inputs to the hook to generate the form
    //placeholder + required + type
    const inputs = {
        "name": ["Titulo", true, "text"],
        "acronym": ["Acronimo", true, "text"],
        "quantClasses": ["Cantidad de clases", false, "number"],
    }

    useEffect(() => {
        const fetchData = async () => {
            const response = await callApi.getCall("course")
            setCourses(response)
            setFiltredData(response)
        }

        document.getElementById('searchInput').focus();
        fetchData();
    }, []);

    const handleFilter = (event) => {
        const searchTerm = event.target.value.toLowerCase();
        const filtered = courses.filter(courses =>
            courses.name.toLowerCase().includes(searchTerm)
        );
        setFiltredData(filtered);
    };

    const deleteCourse = (id, name) => {
        callApi.deleteCall("course/Delete/" + id, 'al curso ' + name);
    }

    const addCourse = () => {
        withReactContent(Swal).fire({
            title: 'Agregar Curso',
            html: <ModalFormCourse idForm="addForm" />,
            width: '60%',
            showConfirmButton: false,
        }).then((result) => {
            if (result.isConfirmed) {
                callApi.callApiNoReadWithModal("course", "#addForm");
            }
        });
    }

    const updateCourse = async (id) => {

        let data = await callApi.getCall("course/" + id);
        await withReactContent(Swal).fire({
            title: 'Modificar Curso',
            html: <ModalFormCourse data={data} inputs={inputs} idForm="updateForm" />,
            width: '60%',
            showConfirmButton: false,
        }).then((result) => {
            if (result.isConfirmed) {
                callApi.callApiNoReadWithModal("course/Update/", "#updateForm", id);
            }
        });
    }

    return (
        <div>
            <main className="container">
                <button onClick={addCourse} className="btn btn-success mt-4">Agregar nuevo Curso</button>
                <input type="text" id="searchInput" placeholder="Buscar Curso" className="form-control mt-4" onChange={handleFilter} />
                <table className="table mt-3 table-hover">
                    <thead>
                        <tr>
                            <th scope="col">Nombre</th>
                            <th scope="col">Acronimo</th>
                            <th scope="col">Cantidad de clases</th>
                            <th scope="col">Modulos</th>
                            <th scope="col">Modificar</th>
                            <th scope="col">Eliminar</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filtredData ? (
                            filtredData.map((item) => (
                                <tr key={item.id}>
                                    <td className="col-md-5">{item.name}</td>
                                    <td className="col-md-2">{item.acronym}</td>
                                    <td className="col-md-2">{item.quantClasses}</td>
                                    <td className="col-md-1">
                                        <Link to={`/modulos/${item.id}`} className="btn btn-primary">Modulos</Link>
                                    </td>                                  
                                    <td className="col-md-1"><button onClick={() => updateCourse(item.id)} className="btn btn-warning">Modificar</button></td>
                                    <td className="col-md-1"><button onClick={() => deleteCourse(item.id, item.name)} className="btn btn-danger">Eliminar</button></td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td>Cargando...</td>
                                <td>Cargando...</td>
                                <td>Cargando...</td>
                                <td></td>
                                <td></td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </main>
        </div>
    )
}
export default Courses