import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import useCallApi from "../hooks/useCallApi";

const ModalFormCourse = (props) => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const callApi = useCallApi();

  const onSubmit = (data) => {
    Swal.clickConfirm();
  };

  const closeModal = () => {
    Swal.clickCancel();
  };

  const handleDeleteModule = async (index, id, name) => {

    await callApi.deleteCall("module/Delete/" + id, 'al modulo ' + name);

  }

  return (
    <form id={props.idForm} className="container mt-5" onSubmit={handleSubmit(onSubmit)}>
      <div className='row justify-content-center mb-3'>
        <div className='col-12'>
          <label className='form-label'>Titulo</label>
          <input
            type="text"
            defaultValue={props.data?.name}
            className={errors.name ? 'mb-4 form-control is-invalid ' : 'mb-4 form-control'}
            {...register('name', { required: true })}
          />
          {errors.name && <span className="text-danger">Titulo es obligatorio</span>}
        </div>

        <div className='col-md-3'>
          <label className='form-label'>Acronimo "Ej -ESI"</label>
          <input
            type="text"
            defaultValue={props.data?.acronym}
            className={errors.acronym ? 'mb-4 form-control is-invalid ' : 'mb-4 form-control'}
            {...register('acronym', { required: true })}
          />
          {errors.acronym && <span className="text-danger">Acronimo es obligatorio</span>}
        </div>

        <div className='col-md-3'>
          <label className='form-label'>Cantidad de clases</label>
          <input
            type="number"
            defaultValue={props.data?.quantClasses}
            className='mb-4 form-control'
            {...register('quantClasses')}
          />
        </div>
      </div>

      <div className="mb-3">
        {props.data?.module && props.data.module.length > 0 && (
          <>
            <h5>Módulos:</h5>
            {props.data?.module.map((mod, index) => (

              <div key={index} className="mt-4 d-flex align-items-center mb-2 justify-content-center">
                <p style={{ width: '50%' }}>{mod.name}</p>
                <button type="button" className="btn btn-danger mx-3" onClick={() => handleDeleteModule(index, mod.id, mod.name)}>Eliminar</button>
              </div>

            ))}
          </>
        )}
      </div>

      <button className='swal2-confirm swal2-styled ' type="submit" >Enviar</button>
      <button onClick={closeModal} type="button" className="swal2-cancel swal2-styled" aria-label="" >Cancelar</button>
    </form>
  );
};

export default ModalFormCourse;
