import { React } from "react";
import { useEffect, useState, useRef } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import useAuth from '../hooks/useAuth';
import { useNavigate, useLocation } from "react-router";


function Login() {

    const userRef = useRef(null);
    const errRef = useRef(null);
    const { setAuth } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const axiosPrivate = useAxiosPrivate();

    const from = location.state?.from?.pathname || "/";

    const [user, setUser] = useState("");
    const [password, setpassword] = useState("");
    const [errMsg, setErrMsg] = useState("");

    useEffect(() => {
        userRef.current.focus();
    }, []);

    const handleSubmit = async (e) => {

        e.preventDefault();
        try {

            let userObj = {
                username: user,
                password: password
            };

            const response = await axiosPrivate.post("Authentication/login", userObj,
                {
                    headers: { 'Content-Type': 'application/json' },
                }
            );

            const accessToken = response?.data?.accessToken;

            setAuth({ user, accessToken });

            setUser('');
            setpassword('');

            navigate(from, { replace: true });

        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 400) {
                setErrMsg('Missing Username or Password');
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized');
            } else {
                setErrMsg('Login Failed');
            }
            errRef.current.focus();
        }
    }

    return (
        <main >
            <section className="container d-flex  justify-content-center">
                <form onSubmit={handleSubmit} className="shadow p-3 mb-5 bg-body rounded row w-25  mt-5">
                <h1>Iniciar Sesion</h1>
                    <div>
                        <div className="col-md-12 mt-3 col-12">
                            <p ref={errRef} className={errMsg ? "errmsg" : "text-muted opacity-50"}>{errMsg}</p>
                        </div>
                        <div className="col-md-12 mt-3 col-12">
                            <label htmlFor="username">Username</label>
                            <input className="form-control" name="username" ref={userRef} type="text" required placeholder="Username" value={user} onChange={(e) => setUser(e.target.value)}></input>
                        </div>

                        <div className="col-md-12 mt-3 col-12">
                            <label htmlFor="password">Contraseña</label>
                            <input className="form-control" name="password" type="password" required placeholder="Contraseña" value={password} onChange={(e) => setpassword(e.target.value)}></input>
                        </div>

                        <div className="col-md-12 mt-3 col-12">
                            <button className="w-100 btn btn-primary"> Sign In</button>
                        </div>

                    </div>
                </form>
            </section>


        </main>
    )
}
export default Login