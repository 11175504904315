import React from 'react';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import useCallApi from "../hooks/useCallApi";

const ModalFormModule = (props) => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const callApi = useCallApi();

  const onSubmit = () => {
    Swal.clickConfirm();
  };

  const closeModal = () => {
    Swal.clickCancel();
  };

  const handleDeleteModule = async (id, name) => {

    await callApi.deleteCall("module/Delete/" + id, 'al modulo ' + name);

  }

  return (
    <form id={props.idForm} className="container mt-5" onSubmit={handleSubmit(onSubmit)}>
      <div className='row justify-content-center mb-3'>
        <div className='col-12'>
          <label className='form-label'>Titulo</label>
          <input
            type="text"
            defaultValue={props.data?.name}
            className='mb-4 form-control'
            {...register('name')}
          />
        </div>

        <div className='col-md-6'>
          <label className='form-label'>Descripcion 1</label>
          <input
            type="text"
            maxLength="41"
            className={errors.description ? 'mb-4 form-control is-invalid ' : 'mb-4 form-control'}
            {...register('descriptions[0]', { required: true })}
          />
        </div>

        <div className='col-md-6'>
          <label className='form-label'>Descripcion 2</label>
          <input
            type="text"
            maxLength="41"
            className={errors.description2 ? 'mb-4 form-control is-invalid ' : 'mb-4 form-control'}
            {...register('descriptions[1]', { required: true })}
          />
        </div>

        <div className='col-md-6'>
          <label className='form-label'>Descripcion 3</label>
          <input
            type="text"
            maxLength="41"
            className={errors.description3 ? 'mb-4 form-control is-invalid ' : 'mb-4 form-control'}
            {...register('descriptions[2]', { required: true })}
          />
        </div>

        <div className='col-md-6'>
          <label className='form-label'>Descripcion 4</label>
          <input
            type="text"
            maxLength="41"
            className={errors.description4 ? 'mb-4 form-control is-invalid ' : 'mb-4 form-control'}
            {...register('descriptions[3]', { required: true })}
          />
        </div>

        <div className='col-md-6'>
          <label className='form-label'>Descripcion 5</label>
          <input
            type="text"
            maxLength="41"
            className={errors.description5 ? 'mb-4 form-control is-invalid ' : 'mb-4 form-control'}
            {...register('descriptions[4]')}
          />
        </div>

        <div className='col-md-6'>
          <label className='form-label'>Descripcion 6</label>
          <input
            type="text"
            maxLength="41"
            className={errors.description6 ? 'mb-4 form-control is-invalid ' : 'mb-4 form-control'}
            {...register('descriptions[5]')}
          />
        </div>

        <div className='col-md-6'>
          <label className='form-label'>Descripcion 7</label>
          <input
            type="text"
            maxLength="41"
            className={errors.description7 ? 'mb-4 form-control is-invalid ' : 'mb-4 form-control'}
            {...register('descriptions[6]')}
          />
        </div>

        <div className='col-md-6'>
          <label className='form-label'>Descripcion 8</label>
          <input
            type="text"
            maxLength="41"
            className={errors.description8 ? 'mb-4 form-control is-invalid ' : 'mb-4 form-control'}
            {...register('descriptions[7]')}
          />
        </div>

        


      </div>


      <button className='swal2-confirm swal2-styled ' type="submit" >Enviar</button>
      <button onClick={closeModal} type="button" className="swal2-cancel swal2-styled" aria-label="" >Cancelar</button>
    </form>
  );
};

export default ModalFormModule;
