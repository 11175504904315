import React, { useRef } from 'react';
import Swal from 'sweetalert2';
import { useState, useEffect } from 'react';
import useCallApi from "../hooks/useCallApi";


const ModalFormGroups = (props) => {

  const inputSearchStudentRef = useRef();


  //Students
  const [students, setStudents] = useState([]);
  const [filtredData, setFiltredData] = useState([]);

  //Courses
  const [courses, setCourses] = useState([]);

  //students of the new group
  const [groupStudents, setGroupStudents] = useState([]);

  //selected course
  const [selectedCourseId, setSelectedCourseId] = useState();

  const callApi = useCallApi();


  const handleCourseChange = (event) => {
    setSelectedCourseId(event.target.value);
  };

  const addGroup = () => {
    let group = {
      IdCourse: selectedCourseId,
      IdStudents: groupStudents.map(student => student.id)
    };
    callApi.callApiNoRead("Group", group);
  };

  const closeModal = () => {
    Swal.clickCancel()
  };

  //handle filter data table
  const handleFilter = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    const filtered = students.filter(students =>
      students.name.toLowerCase().includes(searchTerm) ||
      students.surname.toLowerCase().includes(searchTerm)
    );
    setFiltredData(filtered);
  };


  useEffect(() => {
    const fetchData = async () => {

      let responseStudents = await callApi.getCall("Student/StudentGetShort")

      //for update modal, get all student of one group
      if (props.id !== undefined) {

        //get the students of the group
        let responseGroupStudents = await callApi.getCall("Student/StudentGetShort/" + props.id)
        setGroupStudents(responseGroupStudents)


        // Extract IDs from responseGroupStudents
        let groupStudentIds;
        if (responseGroupStudents) {
          groupStudentIds = responseGroupStudents.map(student => student.id);
          groupStudentIds.forEach((studentId) => {
            responseStudents = responseStudents.filter(studentFilter => studentFilter.id !== studentId);
          });
        }

      } else {
        let responseCourses = await callApi.getCall("Course")
        setCourses(responseCourses);

        //select first course by default
        if (responseCourses.length > 0) {
          setSelectedCourseId(responseCourses[0].id);
        }

      }
      setStudents(responseStudents);
      setFiltredData(responseStudents);



    }

    fetchData();
    // eslint-disable-next-line
  }, [props.id]);


  useEffect(() => {
    // Update filtredData based on the updated list of students
    // This effect will run whenever the students state changes
    const filtered = students.filter(student =>
        student.name.toLowerCase().includes("") ||
        student.surname.toLowerCase().includes("")
    );
    setFiltredData(filtered);
}, [students]); // Dependency: students

  const addStudentToGroup = (student) => {

    if (props.id !== undefined) {
      callApi.callApiNoRead("Group/AddStudentFromGroup/" + student.id + "/" + props.id, null, false)
    }

    setGroupStudents([...groupStudents, student]);

    // clear the input
    inputSearchStudentRef.current.value = '';

    //delete the selected student from the list
    setStudents(students.filter(studentFilter => studentFilter.id !== student.id));
    setFiltredData(filtredData.filter(studentFilter => studentFilter.id !== student.id));
  };

  const deleteStudentFromGroup = (student) => {

    if (props.id !== undefined) {
      callApi.callApiNoRead("Group/DeleteStudentFromGroup/" + student.id + "/" + props.id, null, false)
    }
    //delete the selected student from the group list
    setGroupStudents(groupStudents.filter(studentFilter => studentFilter.id !== student.id));

    //add the selected student to both states
    setStudents([...students, student]);
    setFiltredData([...filtredData, student]);
  };

  const submitButton = () => {
    if (props.id === undefined) {
      return <button onClick={addGroup} className='swal2-confirm swal2-styled ' type="button" >Enviar</button>
    } else
      return <button onClick={closeModal} className='swal2-confirm swal2-styled ' type="button" >Guardar</button>
  }

  const listCourses = () => {

    //for add modal
    if (props.id === undefined) {
      return <div>
        {/* Select with all the courses */}
        <select className='w-50 form-select' value={selectedCourseId} onChange={handleCourseChange}>
          {courses.map((course, index) => (
            <option key={index} value={course.id}>
              {course.name}
            </option>
          ))}
        </select>
      </div>
    }
  }

  return (
    <form id={props.idForm} className="container mt-5">

      {listCourses()}

      <h4 className='my-5'>Alumnos dentro del grupo</h4>
      <table className="table my-5 table-hover">
        <thead>
          <tr>
            <th scope="col">Nombre</th>
            <th scope="col">Apellido</th>
            <th scope='col'>Eliminar del grupo</th>
          </tr>
        </thead>
        <tbody>
          {groupStudents && groupStudents.map((item) => (
            <tr key={item.id}>
              <td className="col-md-2">{item.name}</td>
              <td className="col-md-2">{item.surname}</td>
              <td className='col-md-3'><button type="button" onClick={() => deleteStudentFromGroup(item)} className='btn btn-danger'>Eliminar del grupo</button></td>
            </tr>
          ))}
        </tbody>
      </table>

      {/*inputs submit and close modal */}
      {submitButton()}
      <button onClick={closeModal} type="button" className="swal2-cancel swal2-styled" aria-label="" >Cerrar</button>


      <div className='row mt-5'>
        <div className='col-md-12'>
          <h3>Agregar alumno al grupo</h3>
          <input className='form-control my-4' ref={inputSearchStudentRef} placeholder='Buscar alumno' type='text' onChange={handleFilter} />
        </div>
      </div>
      {/* Table with all the students */}
      <table className="table mt-3 table-hover">
        <thead>
          <tr>
            <th scope="col">Nombre</th>
            <th scope="col">Apellido</th>
            <th scope='col'>Agregar al grupo</th>
          </tr>
        </thead>
        <tbody>
          {filtredData && filtredData.map((item) => (
            <tr key={item.id}>
              <td className="col-md-2">{item.name}</td>
              <td className="col-md-2">{item.surname}</td>
              <td className='col-md-3'><button type="button" onClick={() => addStudentToGroup(item)} className='btn btn-success'>Agregar al grupo</button></td>
            </tr>
          ))}
        </tbody>
      </table>

    </form>
  );
};

export default ModalFormGroups;
