import useAxiosPrivate from "./useAxiosPrivate";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ModalCU from "../components/ModalFormCU";
import useAuth from "./useAuth";

const usecallApiNoReadWithModal = () => {

    const apiUrl = process.env.REACT_APP_API_URL;

    const axiosPrivate = useAxiosPrivate();



    const deleteCall = (url, text, confirmation = true) => {
        Swal.fire({
            icon: 'question',
            title: 'Esta seguro en eliminar ' + text + " ?",
            showCancelButton: true, confirmButtonText: "Eliminar", cancelButtonText: "cancelar"
        }).then((result) => {
            if (result.isConfirmed) {
                return callApiNoRead(url,null,confirmation);
            }
        })
    }

    const updateCall = async (url, urlGetData, inputs, text, idItem) => {

        let data = await callApiRead(urlGetData);
        
        await withReactContent(Swal).fire({
            title: 'Modificar ' + text,
            html: <ModalCU data={data} inputs={inputs} idForm="updateForm" />,
            width: '60%',
            showConfirmButton: false,
        }).then((result) => {
            if (result.isConfirmed) {
                callApiNoReadWithModal(url, "#updateForm", idItem);
            }
        });
    }

    const addCall = (url, inputs, text) => {

        withReactContent(Swal).fire({
            title: 'Agregar ' + text,
            html: <ModalCU inputs={inputs} idForm="addForm" />,
            width: '60%',
            showConfirmButton: false,
        }).then((result) => {
            if (result.isConfirmed) {
                callApiNoReadWithModal(url, "#addForm");
            }
        });
    }

    const getCall = async (url) => {
        return await callApiRead(url)
    }

    //This funcion call to the api with the type of method, url and id of the form where the information was completed
    const callApiNoReadWithModal = (url, formId, idItem) => {
        const data = {};
        // Check if there is a form to process
        if (formId != null) {
            const formData = new FormData(document.querySelector(formId));

            for (let [key, value] of formData.entries()) {
                // Detect if the field name indicates an array (e.g., descriptions[0])
                if (key.includes('[') && key.includes(']')) {
                    // Extract the base name of the array (e.g., 'descriptions' from 'descriptions[0]')
                    const baseName = key.split('[')[0];
                    // Initialize the array if it doesn't exist
                    if (!data[baseName]) {
                        data[baseName] = [];
                    }
                    // Add the value to the array if it's not empty
                    if (value !== '') {
                        data[baseName].push(value);
                    }
                } else if (value !== '') {
                    // For regular fields, just add them to the data object
                    data[key] = value;
                }
            }
        }    
        // If there's an idItem, add it to the data object
        if (idItem !== null) {
            data["id"] = idItem;
        }
        // Call the API with the constructed data object
        callApiNoRead(url, data);
    }
    

    const callApiNoRead = async (url, data, confirmation = true, reload = true) => {

        const response = await axiosPrivate.post(apiUrl + url, data,
            {
                headers: { 'Content-Type': 'application/json' }
            }
        ).then(response => {
            if (confirmation == true) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Operacion realizada con exito!',
                    showConfirmButton: false,
                    timer: 1000
                })
                if (reload){
                    setTimeout(function () {
                        window.location.reload();
                    }, 1150);
                }
                
            }
            return true;
        }).catch(error => {
            const swalDelete = withReactContent(Swal)
            swalDelete.fire({
                icon: 'error',
                title: error.response.data,
                confirmButtonText: "Aceptar"
            })
            return false;
        });
    }


    //This funcion call the api and return the data
    const callApiRead = async (urlGetData) => {
        const controller = new AbortController();
        const response = await axiosPrivate.get(urlGetData  , {
            signal: controller.signal
        }).catch(error => {

            if (error.response.status === 401) {
                //navigate('/login');
            }else{
                let errorMsg;
                try {
                    errorMsg = error.response.data;
                } catch {
                    errorMsg = "Error al conectarse con el servidor"
                }
                withReactContent(Swal).fire({
                    icon: 'error',
                    title: errorMsg,
                    confirmButtonText: "Aceptar"
                })
            }            
        });
        try {
            return response.data
        } catch {
        }

    }

    return { deleteCall, updateCall, addCall, getCall, callApiNoRead, callApiNoReadWithModal };
}

export default usecallApiNoReadWithModal;