import React from 'react'
import { createRoot } from 'react-dom/client';
import App from './components/App';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from './pages/Login';
import NoPage from './pages/NoPage';
import Students from './pages/Students';
import Courses from './pages/Courses';
import Groups from './pages/Groups';
import GroupCertificates from './pages/GroupCertificates';
import { AuthProvider } from './context/AuthProvider';
import RequireAuth from './API & Auth/RequireAuth';
import Unauthorized from './pages/Unauthorize';
import PersistLogin from './API & Auth/PersistLogin';
import Modules from './pages/Modules';
const root = createRoot(document.getElementById('root'));


//Example Roles
const ROLES = {
    'User': 2001,
    'Editor': 1984,
    'Admin': 5150
}

root.render(
        <BrowserRouter>
            <AuthProvider>
                <Routes>
                    <Route path='/' element={<App />}>
                        <Route element={<PersistLogin />}>
                            <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
                                <Route index element={<Groups />} />
                                <Route exact path="Alumnos" element={<Students />} />
                                <Route exact path="Cursos" element={<Courses />} />
                                <Route exact path="Modulos/:idCourse" element={<Modules />} />
                                <Route exact path="Grupos" element={<Groups />} />
                                <Route exact path="Grupos/Certificados/:group" element={<GroupCertificates />} />
                            </Route>
                        </Route>
                    </Route>
                    <Route exact path="Login" element={<Login />} />
                    <Route exact path="unauthorized" element={<Unauthorized />} />
                    <Route exact path="*" element={<NoPage />} />
                </Routes>
            </AuthProvider>
        </BrowserRouter>
);