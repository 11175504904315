import { React } from "react";

function Unauthorized(){
    
     
    return(
        <main className="container">
            <h1 className="text-center mt-5 mb-5">Sin autorizacion</h1>
        </main>
    )
}
export default Unauthorized